const sortedPosts: any[] = [
  {
    label: "Спочатку нові",
    value: "DESC"
  },
  {
    label: "Спочатку давніші",
    value: "ASC"
  }
];

export default sortedPosts;
