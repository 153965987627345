interface IMessageProps {
  size?: number;
  color?: string;
}

export default function Message({ size = 24, color = "none" }: IMessageProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={color}
    >
      <path
        d="M4.20681 17.2822L4.40054 16.7219L4.00198 16.283C2.73851 14.8919 2.02557 13.1634 2.02557 11.2857C2.02557 6.8976 6.31848 3 12.0343 3C17.7044 3 22 6.89529 22 11.2857C22 15.718 17.7077 19.5714 12.0343 19.5714C10.7166 19.5714 9.44786 19.3637 8.35155 18.9978L7.86343 18.835L7.44716 19.1375C6.25197 20.006 4.39186 21 2.05764 21C2.03138 21 2.01359 20.9959 2.00294 20.9922C1.99862 20.9597 1.99972 20.9349 2.00217 20.9197C2.00252 20.9175 2.00288 20.9156 2.00323 20.914C2.03457 20.8839 2.06417 20.8529 2.08866 20.8265C2.14694 20.7638 2.21776 20.6826 2.2962 20.5879C2.45439 20.397 2.66121 20.1301 2.88486 19.8074C3.32697 19.1696 3.86488 18.271 4.20681 17.2822Z"
        stroke="black"
        strokeWidth="2"
      />
    </svg>
  );
}
