import { IBreeds } from "../../../../types/index";

export const breeds: IBreeds = {
  dogs: [
    { label: "Інша", value: "" },
    { label: "Дворняга", value: "Дворняга" },
    {
      label: "Австралійська борза",
      value: "Австралійська борза"
    },
    {
      label: "Австралійська вівчарка",
      value: "Австралійська вівчарка"
    },
    {
      label: "Австралійська пастуша собака",
      value: "Австралійська пастуша собака"
    },
    {
      label: "Австралійський келпі",
      value: "Австралійський келпі"
    },
    {
      label: "Австралійський тер`єр",
      value: "Австралійський тер`єр"
    },
    {
      label: "Австралійський шовковистий тер`єр",
      value: "Австралійський шовковистий тер`єр"
    },
    {
      label: "Австрійська гонча",
      value: "Австрійська гонча"
    },
    {
      label: "Австрійський короткошерстий пінчер",
      value: "Австрійський короткошерстий пінчер"
    },
    {
      label: "Азавак",
      value: "Азавак"
    },
    {
      label: "Азербайджанський гурдбасар",
      value: "Азербайджанський гурдбасар"
    },
    {
      label: "Азорських пастуша собака",
      value: "Азорських пастуша собака"
    },
    {
      label: "Аіді",
      value: "Аіді"
    },
    {
      label: "Айну",
      value: "Айну"
    },
    {
      label: "Акбаш",
      value: "Акбаш"
    },
    {
      label: "Акіта-іну",
      value: "Акіта-іну"
    },
    {
      label: "Алапахський бульдог",
      value: "Алапахський бульдог"
    },
    {
      label: "Алопекіс",
      value: "Алопекіс"
    },
    {
      label: "Альпійська таксоподібні гонча (Альпійський бракк)",
      value: "Альпійська таксоподібні гонча (Альпійський бракк)"
    },
    {
      label: "Аляский маламут",
      value: "Аляский маламут"
    },
    {
      label: "Аляскинский клі-кай",
      value: "Аляскинский клі-кай"
    },
    {
      label: "Аляскинский хаскі",
      value: "Аляскинский хаскі"
    },
    {
      label: "Американська акіта (Велика японська собака)",
      value: "Американська акіта (Велика японська собака)"
    },
    {
      label: "Американська біла вівчарка",
      value: "Американська біла вівчарка"
    },
    {
      label: "Американська блакитна гасконська гонча",
      value: "Американська блакитна гасконська гонча"
    },
    {
      label: "Американська індіанська собака",
      value: "Американська індіанська собака"
    },
    {
      label: "Американська оленяча собака",
      value: "Американська оленяча собака"
    },
    {
      label: "Американський бульдог",
      value: "Американський бульдог"
    },
    {
      label: "Американський водяний спанієль",
      value: "Американський водяний спанієль"
    },
    {
      label: "Американський ескімоський шпіц",
      value: "Американський ескімоський шпіц"
    },
    {
      label: "Американський кокер-спанієль",
      value: "Американський кокер-спанієль"
    },
    {
      label: "Американський піт-бультер`єр",
      value: "Американський піт-бультер`єр"
    },
    {
      label: "Американський стаффордшир-тер`єр",
      value: "Американський стаффордшир-тер`єр"
    },
    {
      label: "Американський той-тер`єр",
      value: "Американський той-тер`єр"
    },
    {
      label: "Американський фоксхаунд",
      value: "Американський фоксхаунд"
    },
    {
      label: "Амурская лайка",
      value: "Амурская лайка"
    },
    {
      label: "Анатолийская вівчарка",
      value: "Анатолийская вівчарка"
    },
    {
      label: "Анатолийский карабаш",
      value: "Анатолийский карабаш"
    },
    {
      label: "Англійський бульдог",
      value: "Англійський бульдог"
    },
    {
      label: "Англійський кокер-спанієль",
      value: "Англійський кокер-спанієль"
    },
    {
      label: "Англійський мастиф",
      value: "Англійський мастиф"
    },
    {
      label: "Англійський сетер",
      value: "Англійський сетер"
    },
    {
      label: "Англійський спрингер-спаніель",
      value: "Англійський спрингер-спаніель"
    },
    {
      label: "Англійський фоксхаунд",
      value: "Англійський фоксхаунд"
    },
    {
      label: "Англо-французька гонча",
      value: "Англо-французька гонча"
    },
    {
      label: "Аппенцеллер-зенненхунд",
      value: "Аппенцеллер-зенненхунд"
    },
    {
      label: "Ар`єжуа",
      value: "Ар`єжуа"
    },
    {
      label: "Аргентинський дог",
      value: "Аргентинський дог"
    },
    {
      label: "Арденнський був`є",
      value: "Арденнський був`є"
    },
    {
      label: "Армійські бракк",
      value: "Армійські бракк"
    },
    {
      label: "Артезиан-нормандський бассет",
      value: "Артезиан-нормандський бассет"
    },
    {
      label: "Артуазская гонча",
      value: "Артуазская гонча"
    },
    {
      label: "Афганський хорт",
      value: "Афганський хорт"
    },
    {
      label: "Африканська голий собака",
      value: "Африканська голий собака"
    },
    {
      label: "Афріканіс",
      value: "Афріканіс"
    },
    {
      label: "Аффен-пінчер",
      value: "Аффен-пінчер"
    },
    {
      label: "Баварська гірська гонча",
      value: "Баварська гірська гонча"
    },
    {
      label: "Бакхмуль",
      value: "Бакхмуль"
    },
    {
      label: "Балканська гонча",
      value: "Балканська гонча"
    },
    {
      label: "Банджарска борза",
      value: "Банджарска борза"
    },
    {
      label: "Бараболі",
      value: "Бараболі"
    },
    {
      label: "Барбе",
      value: "Барбе"
    },
    {
      label: "Басенджи",
      value: "Басенджи"
    },
    {
      label: "Баскське вівчарка",
      value: "Баскське вівчарка"
    },
    {
      label: "Бассет-хаунд",
      value: "Бассет-хаунд"
    },
    {
      label: "Бедлингтон тер`єр",
      value: "Бедлингтон тер`єр"
    },
    {
      label: "Бельгійський гриффон",
      value: "Бельгійський гриффон"
    },
    {
      label: "Бергамськие вівчарка",
      value: "Бергамськие вівчарка"
    },
    {
      label: "Бернська гонча",
      value: "Бернська гонча"
    },
    {
      label: "Бернський зенненхунд",
      value: "Бернський зенненхунд"
    },
    {
      label: "Бівер йоркширський тер`єр",
      value: "Бівер йоркширський тер`єр"
    },
    {
      label: "Бігль",
      value: "Бігль"
    },
    {
      label: "Бігль-Хар`єр",
      value: "Бігль-Хар`єр"
    },
    {
      label: "Бійі",
      value: "Бійі"
    },
    {
      label: "Бішон фризі (Кучерявий бішон)",
      value: "Бішон фризі (Кучерявий бішон)"
    },
    {
      label: "Бладхаунд",
      value: "Бладхаунд"
    },
    {
      label: "Блакитний гасконський бассет",
      value: "Блакитний гасконський бассет"
    },
    {
      label: "Блакитний гасконський гриффон",
      value: "Блакитний гасконський гриффон"
    },
    {
      label: "Блакитний Пікардійський епаньоль",
      value: "Блакитний Пікардійський епаньоль"
    },
    {
      label: "Блакитні гасконський Гончі",
      value: "Блакитні гасконський Гончі"
    },
    {
      label: "Бобтейл",
      value: "Бобтейл"
    },
    {
      label: "Боксер",
      value: "Боксер"
    },
    {
      label: "Болгарський барак",
      value: "Болгарський барак"
    },
    {
      label: "Болон`єз",
      value: "Болон`єз"
    },
    {
      label: "Бордер коллі",
      value: "Бордер коллі"
    },
    {
      label: "Бордер тер`єр",
      value: "Бордер тер`єр"
    },
    {
      label: "Бордоский дог",
      value: "Бордоский дог"
    },
    {
      label: "Борза",
      value: "Борза"
    },
    {
      label: "Бородатий коллі",
      value: "Бородатий коллі"
    },
    {
      label: "Босерон",
      value: "Босерон"
    },
    {
      label: "Боснійська барак",
      value: "Боснійська барак"
    },
    {
      label: "Боснійська грубошерста гонча",
      value: "Боснійська грубошерста гонча"
    },
    {
      label: "Боснійська торняк",
      value: "Боснійська торняк"
    },
    {
      label: "Бостон тер`єр",
      value: "Бостон тер`єр"
    },
    {
      label: "Бразильський мастиф",
      value: "Бразильський мастиф"
    },
    {
      label: "Бразильський тер`єр",
      value: "Бразильський тер`єр"
    },
    {
      label: "Бретонські Гончі",
      value: "Бретонські Гончі"
    },
    {
      label: "Бриар (Французька довгошерста вівчарка)",
      value: "Бриар (Французька довгошерста вівчарка)"
    },
    {
      label: "Британський спаніель",
      value: "Британський спаніель"
    },
    {
      label: "Брохолмер",
      value: "Брохолмер"
    },
    {
      label: "Брюссельський гриффон",
      value: "Брюссельський гриффон"
    },
    {
      label: "Бульдог",
      value: "Бульдог"
    },
    {
      label: "Бульдог",
      value: "Бульдог"
    },
    {
      label: "Бульмастиф",
      value: "Бульмастиф"
    },
    {
      label: "Бультерьер",
      value: "Бультерьер"
    },
    {
      label: "Бурбонский бракк",
      value: "Бурбонский бракк"
    },
    {
      label: "Бургоська лягава",
      value: "Бургоська лягава"
    },
    {
      label: "Бурят-монгольський вовкодав",
      value: "Бурят-монгольський вовкодав"
    },
    {
      label: "Вандейскій гриффон",
      value: "Вандейскій гриффон"
    },
    {
      label: "Вахтельхунд (Німецький перепелиний спанієль)",
      value: "Вахтельхунд (Німецький перепелиний спанієль)"
    },
    {
      label: "Веймаранер (Веймарська лягава)",
      value: "Веймаранер (Веймарська лягава)"
    },
    {
      label: "Велика англо-французька триколірна гонча",
      value: "Велика англо-французька триколірна гонча"
    },
    {
      label: "Велика блакитна гасконська гонча",
      value: "Велика блакитна гасконська гонча"
    },
    {
      label: "Велика піренейська гірська собака",
      value: "Велика піренейська гірська собака"
    },
    {
      label: "Велика швейцарська гірська пастуша собака",
      value: "Велика швейцарська гірська пастуша собака"
    },
    {
      label: "Великий вандейский гриффон",
      value: "Великий вандейский гриффон"
    },
    {
      label: "Великий гасконський сентонжуа",
      value: "Великий гасконський сентонжуа"
    },
    {
      label: "Великий швейцарський зенненхунд",
      value: "Великий швейцарський зенненхунд"
    },
    {
      label: "Веллер",
      value: "Веллер"
    },
    {
      label: "Вельш спрингер спанієль",
      value: "Вельш спрингер спанієль"
    },
    {
      label: "Вельш тер`єр",
      value: "Вельш тер`єр"
    },
    {
      label: "Вельш-коргі пемброк кардиган",
      value: "Вельш-коргі пемброк кардиган"
    },
    {
      label: "Вельш-спрингер-спанієль",
      value: "Вельш-спрингер-спанієль"
    },
    {
      label: "Вест хайленд вайт тер`єр",
      value: "Вест хайленд вайт тер`єр"
    },
    {
      label: "Вест-хайленд-уайт-тер`єр",
      value: "Вест-хайленд-уайт-тер`єр"
    },
    {
      label: "Вестфальська таксоподібні гонча",
      value: "Вестфальська таксоподібні гонча"
    },
    {
      label: "Веттерхаун",
      value: "Веттерхаун"
    },
    {
      label: "Вівчарка",
      value: "Вівчарка"
    },
    {
      label: "Вовча собака Сарлоса",
      value: "Вовча собака Сарлоса"
    },
    {
      label: "Вольпіно-італ`яно",
      value: "Вольпіно-італ`яно"
    },
    {
      label: "Гаванська болонка",
      value: "Гаванська болонка"
    },
    {
      label: "Гампр",
      value: "Гампр"
    },
    {
      label: "Ганноверска гонча",
      value: "Ганноверска гонча"
    },
    {
      label: "Гасконі-сентонжська гонча",
      value: "Гасконі-сентонжська гонча"
    },
    {
      label: "Гладкоморда піренейська вівчарка",
      value: "Гладкоморда піренейська вівчарка"
    },
    {
      label: "Гладкошерстий ретривер",
      value: "Гладкошерстий ретривер"
    },
    {
      label: "Гладкошерстий фокстер`єр",
      value: "Гладкошерстий фокстер`єр"
    },
    {
      label: "Глен-оф-Імаал-тер`єр",
      value: "Глен-оф-Імаал-тер`єр"
    },
    {
      label: "Голландська вівчарка",
      value: "Голландська вівчарка"
    },
    {
      label: "Голландський водяний спанієль",
      value: "Голландський водяний спанієль"
    },
    {
      label: "Голландський гриффон",
      value: "Голландський гриффон"
    },
    {
      label: "Гонча",
      value: "Гонча"
    },
    {
      label: "Гонча Артуа",
      value: "Гонча Артуа"
    },
    {
      label: "Гонча Гамільтона",
      value: "Гонча Гамільтона"
    },
    {
      label: "Гонча Дункера",
      value: "Гонча Дункера"
    },
    {
      label: "Гонча Хюгена",
      value: "Гонча Хюгена"
    },
    {
      label: "Гонча Шиллєра",
      value: "Гонча Шиллєра"
    },
    {
      label: "Гордон-сетер (Шотландський сетер)",
      value: "Гордон-сетер (Шотландський сетер)"
    },
    {
      label: "Грейхаунд",
      value: "Грейхаунд"
    },
    {
      label: "Грецька заяча гонча",
      value: "Грецька заяча гонча"
    },
    {
      label: "Гриффон Кортальса",
      value: "Гриффон Кортальса"
    },
    {
      label: "Грьонлансхунд (Гренландська собака)",
      value: "Грьонлансхунд (Гренландська собака)"
    },
    {
      label: "Грюнендаль (Бельгійська вівчарка)",
      value: "Грюнендаль (Бельгійська вівчарка)"
    },
    {
      label: "Далматин",
      value: "Далматин"
    },
    {
      label: "Датська лягава",
      value: "Датська лягава"
    },
    {
      label: "Датський брохольмер",
      value: "Датський брохольмер"
    },
    {
      label: "Денді даймонд тер`єр",
      value: "Денді даймонд тер`єр"
    },
    {
      label: "Джек-Рассел тер`єр",
      value: "Джек-Рассел тер`єр"
    },
    {
      label: "Дірхаунд",
      value: "Дірхаунд"
    },
    {
      label: "Доберман",
      value: "Доберман"
    },
    {
      label: "Дратхаар",
      value: "Дратхаар"
    },
    {
      label: "Дратхаар (Німецька жорсткошерста лягава)",
      value: "Дратхаар (Німецька жорсткошерста лягава)"
    },
    {
      label: "Древер",
      value: "Древер"
    },
    {
      label: "Дрентська куропаточна собака (Патрійсхонд)",
      value: "Дрентська куропаточна собака (Патрійсхонд)"
    },
    {
      label: "Дункер (Норвезька гонча)",
      value: "Дункер (Норвезька гонча)"
    },
    {
      label: "Евразіер",
      value: "Евразіер"
    },
    {
      label: "Ентлебухер-зенненхунд",
      value: "Ентлебухер-зенненхунд"
    },
    {
      label: "Епаньоль бретон",
      value: "Епаньоль бретон"
    },
    {
      label: "Ердельтер`єр",
      value: "Ердельтер`єр"
    },
    {
      label: "Ескімоська собака",
      value: "Ескімоська собака"
    },
    {
      label: "Естонська гонча",
      value: "Естонська гонча"
    },
    {
      label: "Ештрельская вівчарка",
      value: "Ештрельская вівчарка"
    },
    {
      label: "Жорсткошерстний фокстер`єр",
      value: "Жорсткошерстний фокстер`єр"
    },
    {
      label: "Західносибірська лайка",
      value: "Західносибірська лайка"
    },
    {
      label: "Золотистий ретривер",
      value: "Золотистий ретривер"
    },
    {
      label: "Івицька борза (Поденго)",
      value: "Івицька борза (Поденго)"
    },
    {
      label: "Іллірійська вівчарка",
      value: "Іллірійська вівчарка"
    },
    {
      label: "Ірландський вовкодав",
      value: "Ірландський вовкодав"
    },
    {
      label: "Ірландський водяний спанієль",
      value: "Ірландський водяний спанієль"
    },
    {
      label: "Ірландський Глен оф Імаал тер`єр",
      value: "Ірландський Глен оф Імаал тер`єр"
    },
    {
      label: "Ірландський мягкошерстий пшеничний тер`єр",
      value: "Ірландський мягкошерстий пшеничний тер`єр"
    },
    {
      label: "Ірландський сетер",
      value: "Ірландський сетер"
    },
    {
      label: "Ірландський тер`єр",
      value: "Ірландський тер`єр"
    },
    {
      label: "Ісландська собака",
      value: "Ісландська собака"
    },
    {
      label: "Іспанська водна собака",
      value: "Іспанська водна собака"
    },
    {
      label: "Іспанська гальго",
      value: "Іспанська гальго"
    },
    {
      label: "Іспанська мастиф",
      value: "Іспанська мастиф"
    },
    {
      label: "Істрійська гонча",
      value: "Істрійська гонча"
    },
    {
      label: "Італійський бракк",
      value: "Італійський бракк"
    },
    {
      label: "Італійський сегуджіо (гонча)",
      value: "Італійський сегуджіо (гонча)"
    },
    {
      label: "Італійський спиноне",
      value: "Італійський спиноне"
    },
    {
      label: "Йоркширський тер`єр",
      value: "Йоркширський тер`єр"
    },
    {
      label: "Ка де Бестіарі",
      value: "Ка де Бестіарі"
    },
    {
      label: "Ка-де-бо",
      value: "Ка-де-бо"
    },
    {
      label: "Кавалер кінг Чарльз спанієль",
      value: "Кавалер кінг Чарльз спанієль"
    },
    {
      label: "Кавказька вівчарка",
      value: "Кавказька вівчарка"
    },
    {
      label: "Кай тора-кен (Тигрова собака)",
      value: "Кай тора-кен (Тигрова собака)"
    },
    {
      label: "Канадська біла вівчарка",
      value: "Канадська біла вівчарка"
    },
    {
      label: "Канарський дог",
      value: "Канарський дог"
    },
    {
      label: "Канарський поденко",
      value: "Канарський поденко"
    },
    {
      label: "Кангал",
      value: "Кангал"
    },
    {
      label: "Кане-корсо",
      value: "Кане-корсо"
    },
    {
      label: "Карело-фінська лайка",
      value: "Карело-фінська лайка"
    },
    {
      label: "Карельська ведмежа собака",
      value: "Карельська ведмежа собака"
    },
    {
      label: "Карликовий пінчер",
      value: "Карликовий пінчер"
    },
    {
      label: "Карстская вівчарка",
      value: "Карстская вівчарка"
    },
    {
      label: "Кастру-лаборейру",
      value: "Кастру-лаборейру"
    },
    {
      label: "Каталонська вівчарка",
      value: "Каталонська вівчарка"
    },
    {
      label: "Кеєсхонд",
      value: "Кеєсхонд"
    },
    {
      label: "Келпі",
      value: "Келпі"
    },
    {
      label: "Керн тер`єр",
      value: "Керн тер`єр"
    },
    {
      label: "Керн-тер`єр",
      value: "Керн-тер`єр"
    },
    {
      label: "Керрі-блю-тер`єр",
      value: "Керрі-блю-тер`єр"
    },
    {
      label: "Китайский шнауцер",
      value: "Китайский шнауцер"
    },
    {
      label: "Китайська хохлата собака",
      value: "Китайська хохлата собака"
    },
    {
      label: "Кінг Чарльз спанієль",
      value: "Кінг Чарльз спанієль"
    },
    {
      label: "Кісю",
      value: "Кісю"
    },
    {
      label: "Кламбер-спанієль",
      value: "Кламбер-спанієль"
    },
    {
      label: "Коікерхонд`є",
      value: "Коікерхонд`є"
    },
    {
      label: "Коллі (Шотландська вівчарка)",
      value: "Коллі (Шотландська вівчарка)"
    },
    {
      label: "Комондор",
      value: "Комондор"
    },
    {
      label: "Континентальний той-спанієль (Папільон, Фален)",
      value: "Континентальний той-спанієль (Папільон, Фален)"
    },
    {
      label: "Корейський джиндо",
      value: "Корейський джиндо"
    },
    {
      label: "Котон-де-Тулеар",
      value: "Котон-де-Тулеар"
    },
    {
      label: "Кромфорлендер",
      value: "Кромфорлендер"
    },
    {
      label: "Ксолоітцкуінтлі (Мексиканська гола собака)",
      value: "Ксолоітцкуінтлі (Мексиканська гола собака)"
    },
    {
      label: "Кувас",
      value: "Кувас"
    },
    {
      label: "Курцхаар",
      value: "Курцхаар"
    },
    {
      label: "Курчавошёрстний ретривер (Керлі)",
      value: "Курчавошёрстний ретривер (Керлі)"
    },
    {
      label: "Лабрадор ретривер",
      value: "Лабрадор ретривер"
    },
    {
      label: "Лаготто-романьоло",
      value: "Лаготто-романьоло"
    },
    {
      label: "Лайка",
      value: "Лайка"
    },
    {
      label: "Лакенуа",
      value: "Лакенуа"
    },
    {
      label: "Лангхаар (Німецька довгошерста лягава)",
      value: "Лангхаар (Німецька довгошерста лягава)"
    },
    {
      label: "Ландсир",
      value: "Ландсир"
    },
    {
      label: "Ланкаширські хілер",
      value: "Ланкаширські хілер"
    },
    {
      label: "Лапінпорокойра (Лапландська оленегонних собака)",
      value: "Лапінпорокойра (Лапландська оленегонних собака)"
    },
    {
      label: "Латвійський гонча",
      value: "Латвійський гонча"
    },
    {
      label: "Левеск",
      value: "Левеск"
    },
    {
      label: "Левретка",
      value: "Левретка"
    },
    {
      label: "Лейкленд тер`єр",
      value: "Лейкленд тер`єр"
    },
    {
      label: "Леонбергер",
      value: "Леонбергер"
    },
    {
      label: "Леопардовий собака катахули",
      value: "Леопардовий собака катахули"
    },
    {
      label: "Литовська гонча",
      value: "Литовська гонча"
    },
    {
      label: "Лхаса апсо (Лхасський апсо)",
      value: "Лхаса апсо (Лхасський апсо)"
    },
    {
      label: "Люцернська гонча",
      value: "Люцернська гонча"
    },
    {
      label: "Майорська вівчарка",
      value: "Майорська вівчарка"
    },
    {
      label: "Мала блакитна гасконська гонча",
      value: "Мала блакитна гасконська гонча"
    },
    {
      label: "Мала левова собака (Льовхен)",
      value: "Мала левова собака (Льовхен)"
    },
    {
      label: "Мала мюнстерлендерская лягава",
      value: "Мала мюнстерлендерская лягава"
    },
    {
      label: "Малинуа (Бельгійська вівчарка)",
      value: "Малинуа (Бельгійська вівчарка)"
    },
    {
      label: "Мальтійська болонка (Мальтезе)",
      value: "Мальтійська болонка (Мальтезе)"
    },
    {
      label: "Манчестерський тер`єр (Англійський той-тер`єр)",
      value: "Манчестерський тер`єр (Англійський той-тер`єр)"
    },
    {
      label: "Мареммано-абруццен",
      value: "Мареммано-абруццен"
    },
    {
      label: "Марокканський айді",
      value: "Марокканський айді"
    },
    {
      label: "Мастиф",
      value: "Мастиф"
    },
    {
      label: "Мастіно наполетано",
      value: "Мастіно наполетано"
    },
    {
      label: "Міттельшнауцер",
      value: "Міттельшнауцер"
    },
    {
      label: "Монгольська вівчарка",
      value: "Монгольська вівчарка"
    },
    {
      label: "Мопс",
      value: "Мопс"
    },
    {
      label: "Московська сторожова",
      value: "Московська сторожова"
    },
    {
      label: "Московський дракон",
      value: "Московський дракон"
    },
    {
      label: "Муді",
      value: "Муді"
    },
    {
      label: "Мюнстер Лендер",
      value: "Мюнстер Лендер"
    },
    {
      label: "Ненецька оленегонна лайка (оленегонний шпіц)",
      value: "Ненецька оленегонна лайка (оленегонний шпіц)"
    },
    {
      label: "Нівернезський гриффон",
      value: "Нівернезський гриффон"
    },
    {
      label: "Німецька вівчарка",
      value: "Німецька вівчарка"
    },
    {
      label: "Німецька гонча (Німецький бракк)",
      value: "Німецька гонча (Німецький бракк)"
    },
    {
      label: "Німецький великий шпіц",
      value: "Німецький великий шпіц"
    },
    {
      label: "Німецький дог",
      value: "Німецький дог"
    },
    {
      label: "Німецький малий шпіц",
      value: "Німецький малий шпіц"
    },
    {
      label: "Німецький мисливський тер`єр",
      value: "Німецький мисливський тер`єр"
    },
    {
      label: "Німецький пінчер",
      value: "Німецький пінчер"
    },
    {
      label: "Німецький середній шпіц",
      value: "Німецький середній шпіц"
    },
    {
      label: "Німецький шпіц (Померанський шпіц)",
      value: "Німецький шпіц (Померанський шпіц)"
    },
    {
      label: "Німецький ягдтер`єр",
      value: "Німецький ягдтер`єр"
    },
    {
      label: "Ніхон тер`єр (Японський тер`єр)",
      value: "Ніхон тер`єр (Японський тер`єр)"
    },
    {
      label: "Новошотландський ретривер",
      value: "Новошотландський ретривер"
    },
    {
      label: "Норботтен шпіц",
      value: "Норботтен шпіц"
    },
    {
      label: "Норвезький бухунд",
      value: "Норвезький бухунд"
    },
    {
      label: "Норвезький елькхунд",
      value: "Норвезький елькхунд"
    },
    {
      label: "Норвезький лундехунд",
      value: "Норвезький лундехунд"
    },
    {
      label: "Норвіч тер`єр",
      value: "Норвіч тер`єр"
    },
    {
      label: "Норфолк тер`єр",
      value: "Норфолк тер`єр"
    },
    {
      label: "Ньюфаундленд",
      value: "Ньюфаундленд"
    },
    {
      label: "Овернський бракк",
      value: "Овернський бракк"
    },
    {
      label: "ОДИС",
      value: "ОДИС"
    },
    {
      label: "Оттерхаунд (Видрова собака)",
      value: "Оттерхаунд (Видрова собака)"
    },
    {
      label: "Парсон Рассел тер`єр",
      value: "Парсон Рассел тер`єр"
    },
    {
      label: "Пекінес",
      value: "Пекінес"
    },
    {
      label: "Пердігуеро-де-Бургос",
      value: "Пердігуеро-де-Бургос"
    },
    {
      label: "Перуанська гола собака",
      value: "Перуанська гола собака"
    },
    {
      label: "Південноросійська борза",
      value: "Південноросійська борза"
    },
    {
      label: "Південноросійська вівчарка",
      value: "Південноросійська вівчарка"
    },
    {
      label: "Пікардійська вівчарка",
      value: "Пікардійська вівчарка"
    },
    {
      label: "Пікардійський епаньоль",
      value: "Пікардійський епаньоль"
    },
    {
      label: "Пінчер",
      value: "Пінчер"
    },
    {
      label: "Піренейська вівчарка",
      value: "Піренейська вівчарка"
    },
    {
      label: "Піренейський гірський собака",
      value: "Піренейський гірський собака"
    },
    {
      label: "Піренейський мастиф",
      value: "Піренейський мастиф"
    },
    {
      label: "Поденко канаріо",
      value: "Поденко канаріо"
    },
    {
      label: "Пойнтер",
      value: "Пойнтер"
    },
    {
      label: "Польська гонча (Польський огар)",
      value: "Польська гонча (Польський огар)"
    },
    {
      label: "Польська низинна вівчарка",
      value: "Польська низинна вівчарка"
    },
    {
      label: "Польський харт",
      value: "Польський харт"
    },
    {
      label: "Пон-одемерскій епаньоль",
      value: "Пон-одемерскій епаньоль"
    },
    {
      label: "Порселен",
      value: "Порселен"
    },
    {
      label: "Португальська вівчарка",
      value: "Португальська вівчарка"
    },
    {
      label: "Португальська водна собака",
      value: "Португальська водна собака"
    },
    {
      label: "Португальська лягава",
      value: "Португальська лягава"
    },
    {
      label: "Португальська поденгу",
      value: "Португальська поденгу"
    },
    {
      label: "Португальська сторожова (Рафейро до Алентехо)",
      value: "Португальська сторожова (Рафейро до Алентехо)"
    },
    {
      label: "Посавська гонча",
      value: "Посавська гонча"
    },
    {
      label: "Прямошерстний ретривер",
      value: "Прямошерстний ретривер"
    },
    {
      label: "Пті брабансон",
      value: "Пті брабансон"
    },
    {
      label: "Пуатевенська гонча",
      value: "Пуатевенська гонча"
    },
    {
      label: "Пудель",
      value: "Пудель"
    },
    {
      label: "Пудель-пойнтер",
      value: "Пудель-пойнтер"
    },
    {
      label: "Пулі",
      value: "Пулі"
    },
    {
      label: "Пумі",
      value: "Пумі"
    },
    {
      label: "Різеншнауцер",
      value: "Різеншнауцер"
    },
    {
      label: "Родезийський ріджбек",
      value: "Родезийський ріджбек"
    },
    {
      label: "Російська гонча",
      value: "Російська гонча"
    },
    {
      label: "Російська кольорова болонка",
      value: "Російська кольорова болонка"
    },
    {
      label: "Російська псова борза",
      value: "Російська псова борза"
    },
    {
      label: "Російська ряба гонча",
      value: "Російська ряба гонча"
    },
    {
      label: "Російський довгошерстий той",
      value: "Російський довгошерстий той"
    },
    {
      label: "Російський мисливський спанієль",
      value: "Російський мисливський спанієль"
    },
    {
      label: "Російський той-тер`єр",
      value: "Російський той-тер`єр"
    },
    {
      label: "Російський чорний тер`єр",
      value: "Російський чорний тер`єр"
    },
    {
      label: "Російсько-європейська лайка",
      value: "Російсько-європейська лайка"
    },
    {
      label: "Ротвейлер",
      value: "Ротвейлер"
    },
    {
      label: "Румунська карпатська вівчарка",
      value: "Румунська карпатська вівчарка"
    },
    {
      label: "Румунська міорітская вівчарка",
      value: "Румунська міорітская вівчарка"
    },
    {
      label: "Салюки (перська борза)",
      value: "Салюки (перська борза)"
    },
    {
      label: "Самоїдська собака (Самоїд)",
      value: "Самоїдська собака (Самоїд)"
    },
    {
      label: "Санмігельска вівчарка",
      value: "Санмігельска вівчарка"
    },
    {
      label: "Сассекс спанієль",
      value: "Сассекс спанієль"
    },
    {
      label: "Сен-Жерменський бракк",
      value: "Сен-Жерменський бракк"
    },
    {
      label: "Сенбернар",
      value: "Сенбернар"
    },
    {
      label: "Сенбернар (Альпійський мастиф)",
      value: "Сенбернар (Альпійський мастиф)"
    },
    {
      label: "Сербська гонча",
      value: "Сербська гонча"
    },
    {
      label: "Середньоазіатська вівчарка (Алабай)",
      value: "Середньоазіатська вівчарка (Алабай)"
    },
    {
      label: "Сибірський хаскі",
      value: "Сибірський хаскі"
    },
    {
      label: "Сіба-іну",
      value: "Сіба-іну"
    },
    {
      label: "Сікоку (Шікоку)",
      value: "Сікоку (Шікоку)"
    },
    {
      label: "Сіліхем тер`єр",
      value: "Сіліхем тер`єр"
    },
    {
      label: "Скай-тер`єр",
      value: "Скай-тер`єр"
    },
    {
      label: "Скотч-тер`єр (Шотландський тер`єр)",
      value: "Скотч-тер`єр (Шотландський тер`єр)"
    },
    {
      label: "Словацька гонча (Словацька копів)",
      value: "Словацька гонча (Словацька копів)"
    },
    {
      label: "Словацька жорсткошерста лягава",
      value: "Словацька жорсткошерста лягава"
    },
    {
      label: "Словацька чувач",
      value: "Словацька чувач"
    },
    {
      label: "Слюги (Арабська борза)",
      value: "Слюги (Арабська борза)"
    },
    {
      label: "Смоландська гонча (Смоландстеваре)",
      value: "Смоландська гонча (Смоландстеваре)"
    },
    {
      label: "Собака Баскервілі",
      value: "Собака Баскервілі"
    },
    {
      label: "Спаниєль",
      value: "Спаниєль"
    },
    {
      label: "Спанієль",
      value: "Спанієль"
    },
    {
      label: "Спіноне",
      value: "Спіноне"
    },
    {
      label: "Стабейхаун",
      value: "Стабейхаун"
    },
    {
      label: "Староанглійський бульдог",
      value: "Староанглійський бульдог"
    },
    {
      label: "Стародатская лягава",
      value: "Стародатская лягава"
    },
    {
      label: "Стаффордширський бультер`єр",
      value: "Стаффордширський бультер`єр"
    },
    {
      label: "Східноєвропейська вівчарка",
      value: "Східноєвропейська вівчарка"
    },
    {
      label: "Східносибірська лайка",
      value: "Східносибірська лайка"
    },
    {
      label: "Тази",
      value: "Тази"
    },
    {
      label: "Тайган",
      value: "Тайган"
    },
    {
      label: "Тайський ріджбек",
      value: "Тайський ріджбек"
    },
    {
      label: "Такса",
      value: "Такса"
    },
    {
      label: "Тер`єр",
      value: "Тер`єр"
    },
    {
      label: "Тервюрен (Бельгійська вівчарка)",
      value: "Тервюрен (Бельгійська вівчарка)"
    },
    {
      label: "Тибетський мастиф",
      value: "Тибетський мастиф"
    },
    {
      label: "Тибетський спанієль",
      value: "Тибетський спанієль"
    },
    {
      label: "Тибетський тер`єр",
      value: "Тибетський тер`єр"
    },
    {
      label: "Тірольська гонча",
      value: "Тірольська гонча"
    },
    {
      label: "Тоса-Іну",
      value: "Тоса-Іну"
    },
    {
      label: "Тувинская вівчарка",
      value: "Тувинская вівчарка"
    },
    {
      label: "Угорська борза",
      value: "Угорська борза"
    },
    {
      label: "Угорська вижла",
      value: "Угорська вижла"
    },
    {
      label: "Угорська жесткошерстна лягава",
      value: "Угорська жесткошерстна лягава"
    },
    {
      label: "Угорський кувас",
      value: "Угорський кувас"
    },
    {
      label: "Уельська вівчарка",
      value: "Уельська вівчарка"
    },
    {
      label: "Уіппет",
      value: "Уіппет"
    },
    {
      label: "Фараоновий собака",
      value: "Фараоновий собака"
    },
    {
      label: "Філа бразилейро",
      value: "Філа бразилейро"
    },
    {
      label: "Філд спанієль",
      value: "Філд спанієль"
    },
    {
      label: "Фінська гонча",
      value: "Фінська гонча"
    },
    {
      label: "Фінська лайка",
      value: "Фінська лайка"
    },
    {
      label: "Фінська оленегонна лайка",
      value: "Фінська оленегонна лайка"
    },
    {
      label: "Фінський лаппхунд",
      value: "Фінський лаппхунд"
    },
    {
      label: "Фінський шпіц (Фінська пташина лайка)",
      value: "Фінський шпіц (Фінська пташина лайка)"
    },
    {
      label: "Фландрській був`є",
      value: "Фландрській був`є"
    },
    {
      label: "Фокстер`єр",
      value: "Фокстер`єр"
    },
    {
      label: "Фокстер`єр жорсткошерстий",
      value: "Фокстер`єр жорсткошерстий"
    },
    {
      label: "Фоксхаунд",
      value: "Фоксхаунд"
    },
    {
      label: "Французька болонка",
      value: "Французька болонка"
    },
    {
      label: "Французький брак",
      value: "Французький брак"
    },
    {
      label: "Французький бульдог",
      value: "Французький бульдог"
    },
    {
      label: "Французький епаньоль",
      value: "Французький епаньоль"
    },
    {
      label: "Французький мастиф",
      value: "Французький мастиф"
    },
    {
      label: "Французькі рябі гончі",
      value: "Французькі рябі гончі"
    },
    {
      label: "Хальденская гонча",
      value: "Хальденская гонча"
    },
    {
      label: "Ханаанська собака",
      value: "Ханаанська собака"
    },
    {
      label: "Хар`єр",
      value: "Хар`єр"
    },
    {
      label: "Хігенхунд",
      value: "Хігенхунд"
    },
    {
      label: "Ховаварт",
      value: "Ховаварт"
    },
    {
      label: "Хоккайдо",
      value: "Хоккайдо"
    },
    {
      label: "Хорватська вівчарка",
      value: "Хорватська вівчарка"
    },
    {
      label: "Хорта борза",
      value: "Хорта борза"
    },
    {
      label: "Цвергшнауцер",
      value: "Цвергшнауцер"
    },
    {
      label: "Чау-чау",
      value: "Чау-чау"
    },
    {
      label: "Чесапік бей толлер (Толлер ретривер)",
      value: "Чесапік бей толлер (Толлер ретривер)"
    },
    {
      label: "Чеська вовча собака",
      value: "Чеська вовча собака"
    },
    {
      label: "Чеський тер`єр",
      value: "Чеський тер`єр"
    },
    {
      label: "Чеський фоусек",
      value: "Чеський фоусек"
    },
    {
      label: "Чирнеко дель Етна (Сицилійська борза)",
      value: "Чирнеко дель Етна (Сицилійська борза)"
    },
    {
      label: "Чихуахуа",
      value: "Чихуахуа"
    },
    {
      label: "Чорний тер`єр",
      value: "Чорний тер`єр"
    },
    {
      label: "Чорноподпалий кунхаунд (Єнотова собака)",
      value: "Чорноподпалий кунхаунд (Єнотова собака)"
    },
    {
      label: "Чукотська їздова",
      value: "Чукотська їздова"
    },
    {
      label: "Шапендуа",
      value: "Шапендуа"
    },
    {
      label: "Шарпей",
      value: "Шарпей"
    },
    {
      label: "Шарпланінак",
      value: "Шарпланінак"
    },
    {
      label: "Шведський вальхунд (Вестготашпіц)",
      value: "Шведський вальхунд (Вестготашпіц)"
    },
    {
      label: "Шведський елькхунд",
      value: "Шведський елькхунд"
    },
    {
      label: "Швейцарська гонча",
      value: "Швейцарська гонча"
    },
    {
      label: "Шелті",
      value: "Шелті"
    },
    {
      label: "Ши-тцу",
      value: "Ши-тцу"
    },
    {
      label: "Шипперке",
      value: "Шипперке"
    },
    {
      label: "Шовковистий віндхаунд",
      value: "Шовковистий віндхаунд"
    },
    {
      label: "Шотландський тер`єр",
      value: "Шотландський тер`єр"
    },
    {
      label: "Штирійська гонча",
      value: "Штирійська гонча"
    },
    {
      label: "Штіхельхаар",
      value: "Штіхельхаар"
    },
    {
      label: "Югославська гонча",
      value: "Югославська гонча"
    },
    {
      label: "Ягдтер`єр",
      value: "Ягдтер`єр"
    },
    {
      label: "Якутська лайка",
      value: "Якутська лайка"
    },
    {
      label: "Японський тер`єр",
      value: "Японський тер`єр"
    },
    {
      label: "Японський хін",
      value: "Японський хін"
    },
    {
      label: "Японський шпіц",
      value: "Японський шпіц"
    }
  ],
  cats: [
    { label: "Інша", value: "" },
    {
      label: "Абісинська кішка",
      value: "Абісинська кішка"
    },
    {
      label: "Австралійська димчаста кішка",
      value: "Австралійська димчаста кішка"
    },
    {
      label: "Азійська",
      value: "Азійська"
    },
    {
      label: "Азійська напівдовгошерста або тифані",
      value: "Азійська напівдовгошерста або тифані"
    },
    {
      label: "Американська жорсткошерста кішка",
      value: "Американська жорсткошерста кішка"
    },
    {
      label: "Американська короткошерста кішка",
      value: "Американська короткошерста кішка"
    },
    {
      label: "Американський бобтейл",
      value: "Американський бобтейл"
    },
    {
      label: "Американський керл",
      value: "Американський керл"
    },
    {
      label: "Анатолійська кішка",
      value: "Анатолійська кішка"
    },
    {
      label: "Американський полідактиль",
      value: "Американський полідактиль"
    },
    {
      label: "Арабська мау",
      value: "Арабська мау"
    },
    {
      label: "Ашера",
      value: "Ашера"
    },
    {
      label: "Балійська кішка",
      value: "Балійська кішка"
    },
    {
      label: "Бенгальська кішка",
      value: "Бенгальська кішка"
    },
    {
      label: "Бірманська кішка",
      value: "Бірманська кішка"
    },
    {
      label: "Блакитноока кішка",
      value: "Блакитноока кішка"
    },
    {
      label: "Бомбейська кішка",
      value: "Бомбейська кішка"
    },
    {
      label: "Бразильська короткошерста кішка",
      value: "Бразильська короткошерста кішка"
    },
    {
      label: "Британська довгошерста",
      value: "Британська довгошерста"
    },
    {
      label: "Британська короткошерста кішка",
      value: "Британська короткошерста кішка"
    },
    {
      label: "Бурманська кішка",
      value: "Бурманська кішка"
    },
    {
      label: "Бурмила",
      value: "Бурмила"
    },
    {
      label: "Гавана браун",
      value: "Гавана браун"
    },
    {
      label: "Гімалайська кішка",
      value: "Гімалайська кішка"
    },
    {
      label: "Девон-рекс",
      value: "Девон-рекс"
    },
    {
      label: "Донський сфінкс",
      value: "Донський сфінкс"
    },
    {
      label: "Дракон Лі",
      value: "Дракон Лі"
    },
    {
      label: "Егейська кішка",
      value: "Егейська кішка"
    },
    {
      label: "Екзотична короткошерста",
      value: "Екзотична короткошерста"
    },
    {
      label: "Європейська короткошерста",
      value: "Європейська короткошерста"
    },
    {
      label: "Єгипетська мау",
      value: "Єгипетська мау"
    },
    {
      label: "Йоркська шоколадна кішка",
      value: "Йоркська шоколадна кішка"
    },
    {
      label: "Каліфорнійська кішка",
      value: "Каліфорнійська кішка"
    },
    {
      label: "Канаані",
      value: "Канаані"
    },
    {
      label: "Као-мані",
      value: "Као-мані"
    },
    {
      label: "Карельський бобтейл",
      value: "Карельський бобтейл"
    },
    {
      label: "Кімрійська або Кімрик",
      value: "Кімрійська або Кімрик"
    },
    {
      label: "Корат",
      value: "Корат"
    },
    {
      label: "Корніш-рекс",
      value: "Корніш-рекс"
    },
    {
      label: "Короткошерстий колорпоінт",
      value: "Короткошерстий колорпоінт"
    },
    {
      label: "Курильський бобтейл",
      value: "Курильський бобтейл"
    },
    {
      label: "ЛаПерм",
      value: "ЛаПерм"
    },
    {
      label: "Лікой",
      value: "Лікой"
    },
    {
      label: "Манчкін",
      value: "Манчкін"
    },
    {
      label: "Мейн-кун",
      value: "Мейн-кун"
    },
    {
      label: "Менська кішка або Менкс",
      value: "Менська кішка або Менкс"
    },
    {
      label: "Нібелунг",
      value: "Нібелунг"
    },
    {
      label: "Німецький рекс",
      value: "Німецький рекс"
    },
    {
      label: "Норвезька лісова",
      value: "Норвезька лісова"
    },
    {
      label: "Орегонський рекс",
      value: "Орегонський рекс"
    },
    {
      label: "Орієнтальна",
      value: "Орієнтальна"
    },
    {
      label: "Орієнтальна довгошерста або яванез",
      value: "Орієнтальна довгошерста або яванез"
    },
    {
      label: "Орієнтальний біколор",
      value: "Орієнтальний біколор"
    },
    {
      label: "Оцикет",
      value: "Оцикет"
    },
    {
      label: "Перська кішка",
      value: "Перська кішка"
    },
    {
      label: "Піксибоб",
      value: "Піксибоб"
    },
    {
      label: "Пітерболд або петербурзький сфінкс",
      value: "Пітерболд або петербурзький сфінкс"
    },
    {
      label: "Пуделькет",
      value: "Пуделькет"
    },
    {
      label: "Раґамаффін",
      value: "Раґамаффін"
    },
    {
      label: "Регдол",
      value: "Регдол"
    },
    {
      label: "Російська біла, чорна і таббі",
      value: "Російська біла, чорна і таббі"
    },
    {
      label: "Російська блакитна",
      value: "Російська блакитна"
    },
    {
      label: "Саванна",
      value: "Саванна"
    },
    {
      label: "Сейшельська",
      value: "Сейшельська"
    },
    {
      label: "Селкірк-рекс",
      value: "Селкірк-рекс"
    },
    {
      label: "Серегнеті",
      value: "Серегнеті"
    },
    {
      label: "Сибірська кішка",
      value: "Сибірська кішка"
    },
    {
      label: "Сингапурська кішка",
      value: "Сингапурська кішка"
    },
    {
      label: "Сіамська кішка",
      value: "Сіамська кішка"
    },
    {
      label: "Сквіттен",
      value: "Сквіттен"
    },
    {
      label: "Скотіш фолд або Шотландська висловуха",
      value: "Скотіш фолд або Шотландська висловуха"
    },
    {
      label: "Сноу-шу",
      value: "Сноу-шу"
    },
    {
      label: "Сомалійська",
      value: "Сомалійська"
    },
    {
      label: "Соукок",
      value: "Соукок"
    },
    {
      label: "Сумксу",
      value: "Сумксу"
    },
    {
      label: "Сфінкс",
      value: "Сфінкс"
    },
    {
      label: "Тайська",
      value: "Тайська"
    },
    {
      label: "Тайський бобтейл",
      value: "Тайський бобтейл"
    },
    {
      label: "Той-бобтейл",
      value: "Той-бобтейл"
    },
    {
      label: "Тойгер",
      value: "Тойгер"
    },
    {
      label: "Тонкінська",
      value: "Тонкінська"
    },
    {
      label: "Турецька ангора",
      value: "Турецька ангора"
    },
    {
      label: "Турецький ван",
      value: "Турецький ван"
    },
    {
      label: "Український левкой",
      value: "Український левкой"
    },
    {
      label: "Уссурі",
      value: "Уссурі"
    },
    {
      label: "Уральський рекс",
      value: "Уральський рекс"
    },
    {
      label: "Четог",
      value: "Четог"
    },
    {
      label: "Шантільї-Тіфані",
      value: "Шантільї-Тіфані"
    },
    {
      label: "Шартрез",
      value: "Шартрез"
    },
    {
      label: "Шаузі",
      value: "Шаузі"
    },
    {
      label: "Японський бобтейл",
      value: "Японський бобтейл"
    }
  ]
};
