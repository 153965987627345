/* tslint:disable */
/* eslint-disable */
/**
 * Sniff API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const ActionType = {
    Update: 'UPDATE',
    Delete: 'DELETE'
} as const;

export type ActionType = typeof ActionType[keyof typeof ActionType];


/**
 * 
 * @export
 * @interface AuditLog
 */
export interface AuditLog {
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    'adminId'?: number;
    /**
     * 
     * @type {number}
     * @memberof AuditLog
     */
    'objectId'?: number;
    /**
     * 
     * @type {ObjectType}
     * @memberof AuditLog
     */
    'objectType'?: ObjectType;
    /**
     * 
     * @type {ActionType}
     * @memberof AuditLog
     */
    'actionType'?: ActionType;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'actionDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof AuditLog
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Castration = {
    Yes: 'YES',
    No: 'NO',
    Unknown: 'UNKNOWN'
} as const;

export type Castration = typeof Castration[keyof typeof Castration];


/**
 * 
 * @export
 * @enum {string}
 */

export const Diseases = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type Diseases = typeof Diseases[keyof typeof Diseases];


/**
 * 
 * @export
 * @enum {string}
 */

export const Gender = {
    Male: 'MALE',
    Female: 'FEMALE',
    Unknown: 'UNKNOWN'
} as const;

export type Gender = typeof Gender[keyof typeof Gender];


/**
 * 
 * @export
 * @enum {string}
 */

export const HideContacts = {
    Yes: 'YES',
    No: 'NO'
} as const;

export type HideContacts = typeof HideContacts[keyof typeof HideContacts];


/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'region'?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    'district'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const LogOrder = {
    Asc: 'ACTION_DATE_ASC',
    Desc: 'ACTION_DATE_DESC'
} as const;

export type LogOrder = typeof LogOrder[keyof typeof LogOrder];


/**
 * 
 * @export
 * @interface NewUser
 */
export interface NewUser {
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    'email': string;
    /**
     * 
     * @type {Location}
     * @memberof NewUser
     */
    'location'?: Location;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    'organisation'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewUser
     */
    'secret'?: string;
    /**
     * 
     * @type {HideContacts}
     * @memberof NewUser
     */
    'hideContacts'?: HideContacts;
    /**
     * 
     * @type {Role}
     * @memberof NewUser
     */
    'role'?: Role;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const ObjectType = {
    User: 'USER',
    Post: 'POST'
} as const;

export type ObjectType = typeof ObjectType[keyof typeof ObjectType];


/**
 * 
 * @export
 * @interface Pet
 */
export interface Pet {
    /**
     * 
     * @type {number}
     * @memberof Pet
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'name'?: string;
    /**
     * 
     * @type {PetType}
     * @memberof Pet
     */
    'type': PetType;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'breed'?: string;
    /**
     * 
     * @type {string}
     * @memberof Pet
     */
    'color'?: string;
    /**
     * 
     * @type {Gender}
     * @memberof Pet
     */
    'gender'?: Gender;
    /**
     * 
     * @type {PetAge}
     * @memberof Pet
     */
    'age'?: PetAge;
    /**
     * 
     * @type {PetSize}
     * @memberof Pet
     */
    'size'?: PetSize;
    /**
     * 
     * @type {Vaccine}
     * @memberof Pet
     */
    'vaccine'?: Vaccine;
    /**
     * 
     * @type {Castration}
     * @memberof Pet
     */
    'castration'?: Castration;
    /**
     * 
     * @type {Diseases}
     * @memberof Pet
     */
    'diseases'?: Diseases;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PetAge = {
    _01: '_0_1',
    _13: '_1_3',
    _46: '_4_6',
    _610: '_6_10',
    Above10: 'ABOVE_10',
    Unknown: 'UNKNOWN'
} as const;

export type PetAge = typeof PetAge[keyof typeof PetAge];


/**
 * 
 * @export
 * @enum {string}
 */

export const PetSize = {
    Small: 'SMALL',
    Medium: 'MEDIUM',
    Large: 'LARGE'
} as const;

export type PetSize = typeof PetSize[keyof typeof PetSize];


/**
 * 
 * @export
 * @enum {string}
 */

export const PetType = {
    Dog: 'DOG',
    Cat: 'CAT',
    Other: 'OTHER'
} as const;

export type PetType = typeof PetType[keyof typeof PetType];


/**
 * 
 * @export
 * @interface PhotoSaveResult
 */
export interface PhotoSaveResult {
    /**
     * 
     * @type {number}
     * @memberof PhotoSaveResult
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PhotoSaveResult
     */
    'fileName': string;
    /**
     * 
     * @type {PhotoSaveStatus}
     * @memberof PhotoSaveResult
     */
    'saveStatus': PhotoSaveStatus;
    /**
     * 
     * @type {string}
     * @memberof PhotoSaveResult
     */
    'comment'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PhotoSaveStatus = {
    Saved: 'SAVED',
    Invalid: 'INVALID',
    Ignored: 'IGNORED'
} as const;

export type PhotoSaveStatus = typeof PhotoSaveStatus[keyof typeof PhotoSaveStatus];


/**
 * 
 * @export
 * @interface Post
 */
export interface Post {
    /**
     * 
     * @type {number}
     * @memberof Post
     */
    'id'?: number;
    /**
     * 
     * @type {PostType}
     * @memberof Post
     */
    'type': PostType;
    /**
     * 
     * @type {User}
     * @memberof Post
     */
    'author': User;
    /**
     * 
     * @type {Pet}
     * @memberof Post
     */
    'pet': Pet;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'story'?: string;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'postCreatedDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'lostDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'foundDate'?: string;
    /**
     * 
     * @type {Location}
     * @memberof Post
     */
    'foundLocation'?: Location;
    /**
     * 
     * @type {Location}
     * @memberof Post
     */
    'currentLocation'?: Location;
    /**
     * 
     * @type {Location}
     * @memberof Post
     */
    'lostLocation'?: Location;
    /**
     * 
     * @type {Array<number>}
     * @memberof Post
     */
    'photoIds'?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Post
     */
    'contactPhone'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PostType = {
    Lost: 'LOST',
    Found: 'FOUND'
} as const;

export type PostType = typeof PostType[keyof typeof PostType];


/**
 * 
 * @export
 * @enum {string}
 */

export const PostsOrder = {
    Asc: 'ASC',
    Desc: 'DESC'
} as const;

export type PostsOrder = typeof PostsOrder[keyof typeof PostsOrder];


/**
 * 
 * @export
 * @enum {string}
 */

export const Role = {
    User: 'USER',
    Admin: 'ADMIN'
} as const;

export type Role = typeof Role[keyof typeof Role];


/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'firstName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastName': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'phone': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {Location}
     * @memberof User
     */
    'location'?: Location;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'organisation'?: string;
    /**
     * 
     * @type {HideContacts}
     * @memberof User
     */
    'hideContacts'?: HideContacts;
    /**
     * 
     * @type {Role}
     * @memberof User
     */
    'role'?: Role;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'created'?: string;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const Vaccine = {
    Yes: 'YES',
    No: 'NO',
    Unknown: 'UNKNOWN'
} as const;

export type Vaccine = typeof Vaccine[keyof typeof Vaccine];



/**
 * LogApi - axios parameter creator
 * @export
 */
export const LogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} lastLogId 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs: async (lastLogId: number, limit: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'lastLogId' is not null or undefined
            assertParamExists('getLogs', 'lastLogId', lastLogId)
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getLogs', 'limit', limit)
            const localVarPath = `/logs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (lastLogId !== undefined) {
                localVarQueryParameter['lastLogId'] = lastLogId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogApi - functional programming interface
 * @export
 */
export const LogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} lastLogId 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLogs(lastLogId: number, limit: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AuditLog>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLogs(lastLogId, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LogApi - factory interface
 * @export
 */
export const LogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogApiFp(configuration)
    return {
        /**
         * 
         * @param {number} lastLogId 
         * @param {number} limit 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(lastLogId: number, limit: number, options?: any): AxiosPromise<Array<AuditLog>> {
            return localVarFp.getLogs(lastLogId, limit, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LogApi - object-oriented interface
 * @export
 * @class LogApi
 * @extends {BaseAPI}
 */
export class LogApi extends BaseAPI {
    /**
     * 
     * @param {number} lastLogId 
     * @param {number} limit 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogApi
     */
    public getLogs(lastLogId: number, limit: number, options?: AxiosRequestConfig) {
        return LogApiFp(this.configuration).getLogs(lastLogId, limit, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PostApi - axios parameter creator
 * @export
 */
export const PostApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {Post} [post] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPost: async (post?: Post, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(post, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost: async (postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('deletePost', 'postId', postId)
            const localVarPath = `/posts/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} postId 
         * @param {number} photoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostPhotoById: async (postId: number, photoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('deletePostPhotoById', 'postId', postId)
            // verify required parameter 'photoId' is not null or undefined
            assertParamExists('deletePostPhotoById', 'photoId', photoId)
            const localVarPath = `/posts/{postId}/photos/{photoId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"photoId"}}`, encodeURIComponent(String(photoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostById: async (postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getPostById', 'postId', postId)
            const localVarPath = `/posts/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} postId 
         * @param {number} photoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostPhotoById: async (postId: number, photoId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('getPostPhotoById', 'postId', postId)
            // verify required parameter 'photoId' is not null or undefined
            assertParamExists('getPostPhotoById', 'photoId', photoId)
            const localVarPath = `/posts/{postId}/photos/{photoId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)))
                .replace(`{${"photoId"}}`, encodeURIComponent(String(photoId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} lastId 
         * @param {PostsOrder} order 
         * @param {number} [userId] 
         * @param {PetType} [petType] 
         * @param {string} [petBreed] 
         * @param {string} [petColor] 
         * @param {PetSize} [petSize] 
         * @param {PetAge} [petAge] 
         * @param {Vaccine} [vaccine] 
         * @param {Castration} [castration] 
         * @param {Gender} [gender] 
         * @param {Diseases} [diseases] 
         * @param {PostType} [postType] 
         * @param {string} [lostDateAfter] 
         * @param {string} [foundDateAfter] 
         * @param {string} [postCreatedDateAfter] 
         * @param {string} [region] 
         * @param {string} [city] 
         * @param {string} [district] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts: async (limit: number, lastId: number, order: PostsOrder, userId?: number, petType?: PetType, petBreed?: string, petColor?: string, petSize?: PetSize, petAge?: PetAge, vaccine?: Vaccine, castration?: Castration, gender?: Gender, diseases?: Diseases, postType?: PostType, lostDateAfter?: string, foundDateAfter?: string, postCreatedDateAfter?: string, region?: string, city?: string, district?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getPosts', 'limit', limit)
            // verify required parameter 'lastId' is not null or undefined
            assertParamExists('getPosts', 'lastId', lastId)
            // verify required parameter 'order' is not null or undefined
            assertParamExists('getPosts', 'order', order)
            const localVarPath = `/posts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (lastId !== undefined) {
                localVarQueryParameter['lastId'] = lastId;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (petType !== undefined) {
                localVarQueryParameter['petType'] = petType;
            }

            if (petBreed !== undefined) {
                localVarQueryParameter['petBreed'] = petBreed;
            }

            if (petColor !== undefined) {
                localVarQueryParameter['petColor'] = petColor;
            }

            if (petSize !== undefined) {
                localVarQueryParameter['petSize'] = petSize;
            }

            if (petAge !== undefined) {
                localVarQueryParameter['petAge'] = petAge;
            }

            if (vaccine !== undefined) {
                localVarQueryParameter['vaccine'] = vaccine;
            }

            if (castration !== undefined) {
                localVarQueryParameter['castration'] = castration;
            }

            if (gender !== undefined) {
                localVarQueryParameter['gender'] = gender;
            }

            if (diseases !== undefined) {
                localVarQueryParameter['diseases'] = diseases;
            }

            if (postType !== undefined) {
                localVarQueryParameter['post_type'] = postType;
            }

            if (lostDateAfter !== undefined) {
                localVarQueryParameter['lostDateAfter'] = (lostDateAfter as any instanceof Date) ?
                    (lostDateAfter as any).toISOString().substr(0,10) :
                    lostDateAfter;
            }

            if (foundDateAfter !== undefined) {
                localVarQueryParameter['foundDateAfter'] = (foundDateAfter as any instanceof Date) ?
                    (foundDateAfter as any).toISOString().substr(0,10) :
                    foundDateAfter;
            }

            if (postCreatedDateAfter !== undefined) {
                localVarQueryParameter['postCreatedDateAfter'] = (postCreatedDateAfter as any instanceof Date) ?
                    (postCreatedDateAfter as any).toISOString().substr(0,10) :
                    postCreatedDateAfter;
            }

            if (region !== undefined) {
                localVarQueryParameter['region'] = region;
            }

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (district !== undefined) {
                localVarQueryParameter['district'] = district;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} postId 
         * @param {Post} [post] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostById: async (postId: number, post?: Post, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('updatePostById', 'postId', postId)
            const localVarPath = `/posts/{postId}`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(post, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} postId 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPostPhotos: async (postId: number, files: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('uploadPostPhotos', 'postId', postId)
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadPostPhotos', 'files', files)
            const localVarPath = `/posts/{postId}/photos`
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PostApi - functional programming interface
 * @export
 */
export const PostApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PostApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {Post} [post] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addPost(post?: Post, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addPost(post, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePost(postId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePost(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} postId 
         * @param {number} photoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePostPhotoById(postId: number, photoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePostPhotoById(postId, photoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostById(postId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostById(postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} postId 
         * @param {number} photoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPostPhotoById(postId: number, photoId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPostPhotoById(postId, photoId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} lastId 
         * @param {PostsOrder} order 
         * @param {number} [userId] 
         * @param {PetType} [petType] 
         * @param {string} [petBreed] 
         * @param {string} [petColor] 
         * @param {PetSize} [petSize] 
         * @param {PetAge} [petAge] 
         * @param {Vaccine} [vaccine] 
         * @param {Castration} [castration] 
         * @param {Gender} [gender] 
         * @param {Diseases} [diseases] 
         * @param {PostType} [postType] 
         * @param {string} [lostDateAfter] 
         * @param {string} [foundDateAfter] 
         * @param {string} [postCreatedDateAfter] 
         * @param {string} [region] 
         * @param {string} [city] 
         * @param {string} [district] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPosts(limit: number, lastId: number, order: PostsOrder, userId?: number, petType?: PetType, petBreed?: string, petColor?: string, petSize?: PetSize, petAge?: PetAge, vaccine?: Vaccine, castration?: Castration, gender?: Gender, diseases?: Diseases, postType?: PostType, lostDateAfter?: string, foundDateAfter?: string, postCreatedDateAfter?: string, region?: string, city?: string, district?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Post>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPosts(limit, lastId, order, userId, petType, petBreed, petColor, petSize, petAge, vaccine, castration, gender, diseases, postType, lostDateAfter, foundDateAfter, postCreatedDateAfter, region, city, district, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} postId 
         * @param {Post} [post] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePostById(postId: number, post?: Post, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Post>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePostById(postId, post, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} postId 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadPostPhotos(postId: number, files: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PhotoSaveResult>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadPostPhotos(postId, files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PostApi - factory interface
 * @export
 */
export const PostApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PostApiFp(configuration)
    return {
        /**
         * 
         * @param {Post} [post] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addPost(post?: Post, options?: any): AxiosPromise<Post> {
            return localVarFp.addPost(post, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePost(postId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePost(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} postId 
         * @param {number} photoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePostPhotoById(postId: number, photoId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deletePostPhotoById(postId, photoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostById(postId: number, options?: any): AxiosPromise<Post> {
            return localVarFp.getPostById(postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} postId 
         * @param {number} photoId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPostPhotoById(postId: number, photoId: number, options?: any): AxiosPromise<File> {
            return localVarFp.getPostPhotoById(postId, photoId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} lastId 
         * @param {PostsOrder} order 
         * @param {number} [userId] 
         * @param {PetType} [petType] 
         * @param {string} [petBreed] 
         * @param {string} [petColor] 
         * @param {PetSize} [petSize] 
         * @param {PetAge} [petAge] 
         * @param {Vaccine} [vaccine] 
         * @param {Castration} [castration] 
         * @param {Gender} [gender] 
         * @param {Diseases} [diseases] 
         * @param {PostType} [postType] 
         * @param {string} [lostDateAfter] 
         * @param {string} [foundDateAfter] 
         * @param {string} [postCreatedDateAfter] 
         * @param {string} [region] 
         * @param {string} [city] 
         * @param {string} [district] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosts(limit: number, lastId: number, order: PostsOrder, userId?: number, petType?: PetType, petBreed?: string, petColor?: string, petSize?: PetSize, petAge?: PetAge, vaccine?: Vaccine, castration?: Castration, gender?: Gender, diseases?: Diseases, postType?: PostType, lostDateAfter?: string, foundDateAfter?: string, postCreatedDateAfter?: string, region?: string, city?: string, district?: string, options?: any): AxiosPromise<Array<Post>> {
            return localVarFp.getPosts(limit, lastId, order, userId, petType, petBreed, petColor, petSize, petAge, vaccine, castration, gender, diseases, postType, lostDateAfter, foundDateAfter, postCreatedDateAfter, region, city, district, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} postId 
         * @param {Post} [post] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePostById(postId: number, post?: Post, options?: any): AxiosPromise<Post> {
            return localVarFp.updatePostById(postId, post, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} postId 
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadPostPhotos(postId: number, files: Array<File>, options?: any): AxiosPromise<Array<PhotoSaveResult>> {
            return localVarFp.uploadPostPhotos(postId, files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PostApi - object-oriented interface
 * @export
 * @class PostApi
 * @extends {BaseAPI}
 */
export class PostApi extends BaseAPI {
    /**
     * 
     * @param {Post} [post] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public addPost(post?: Post, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).addPost(post, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public deletePost(postId: number, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).deletePost(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} postId 
     * @param {number} photoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public deletePostPhotoById(postId: number, photoId: number, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).deletePostPhotoById(postId, photoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public getPostById(postId: number, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).getPostById(postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} postId 
     * @param {number} photoId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public getPostPhotoById(postId: number, photoId: number, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).getPostPhotoById(postId, photoId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} lastId 
     * @param {PostsOrder} order 
     * @param {number} [userId] 
     * @param {PetType} [petType] 
     * @param {string} [petBreed] 
     * @param {string} [petColor] 
     * @param {PetSize} [petSize] 
     * @param {PetAge} [petAge] 
     * @param {Vaccine} [vaccine] 
     * @param {Castration} [castration] 
     * @param {Gender} [gender] 
     * @param {Diseases} [diseases] 
     * @param {PostType} [postType] 
     * @param {string} [lostDateAfter] 
     * @param {string} [foundDateAfter] 
     * @param {string} [postCreatedDateAfter] 
     * @param {string} [region] 
     * @param {string} [city] 
     * @param {string} [district] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public getPosts(limit: number, lastId: number, order: PostsOrder, userId?: number, petType?: PetType, petBreed?: string, petColor?: string, petSize?: PetSize, petAge?: PetAge, vaccine?: Vaccine, castration?: Castration, gender?: Gender, diseases?: Diseases, postType?: PostType, lostDateAfter?: string, foundDateAfter?: string, postCreatedDateAfter?: string, region?: string, city?: string, district?: string, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).getPosts(limit, lastId, order, userId, petType, petBreed, petColor, petSize, petAge, vaccine, castration, gender, diseases, postType, lostDateAfter, foundDateAfter, postCreatedDateAfter, region, city, district, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} postId 
     * @param {Post} [post] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public updatePostById(postId: number, post?: Post, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).updatePostById(postId, post, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} postId 
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PostApi
     */
    public uploadPostPhotos(postId: number, files: Array<File>, options?: AxiosRequestConfig) {
        return PostApiFp(this.configuration).uploadPostPhotos(postId, files, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} [body] Add favourite post id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserFavourite: async (userId: number, body?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('addUserFavourite', 'userId', userId)
            const localVarPath = `/users/{userId}/favourites`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUser', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFavourite: async (userId: number, postId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('deleteUserFavourite', 'userId', userId)
            // verify required parameter 'postId' is not null or undefined
            assertParamExists('deleteUserFavourite', 'postId', postId)
            const localVarPath = `/users/{userId}/favourites/{postId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"postId"}}`, encodeURIComponent(String(postId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserById', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBySearchTerm: async (searchTerm: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchTerm' is not null or undefined
            assertParamExists('getUserBySearchTerm', 'searchTerm', searchTerm)
            const localVarPath = `/search-users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (searchTerm !== undefined) {
                localVarQueryParameter['searchTerm'] = searchTerm;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavourite: async (userId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserFavourite', 'userId', userId)
            const localVarPath = `/users/{userId}/favourites`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} lastId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers: async (limit: number, lastId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'limit' is not null or undefined
            assertParamExists('getUsers', 'limit', limit)
            // verify required parameter 'lastId' is not null or undefined
            assertParamExists('getUsers', 'lastId', lastId)
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (lastId !== undefined) {
                localVarQueryParameter['lastId'] = lastId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register new user
         * @param {NewUser} [newUser] New user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser: async (newUser?: NewUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Register new user with OAuth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserWithOAuth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/oauth/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {NewUser} [newUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById: async (userId: number, newUser?: NewUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserById', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newUser, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRoleById: async (userId: number, body?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('updateUserRoleById', 'userId', userId)
            const localVarPath = `/users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} [body] Add favourite post id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUserFavourite(userId: number, body?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUserFavourite(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUser(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUser(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserFavourite(userId: number, postId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserFavourite(userId, postId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCurrentUser(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCurrentUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserById(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserById(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserBySearchTerm(searchTerm: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserBySearchTerm(searchTerm, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserFavourite(userId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Set<number>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserFavourite(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} lastId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUsers(limit: number, lastId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<User>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUsers(limit, lastId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register new user
         * @param {NewUser} [newUser] New user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUser(newUser?: NewUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUser(newUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Register new user with OAuth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerUserWithOAuth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerUserWithOAuth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {NewUser} [newUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserById(userId: number, newUser?: NewUser, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserById(userId, newUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRoleById(userId: number, body?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRoleById(userId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {number} userId 
         * @param {number} [body] Add favourite post id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUserFavourite(userId: number, body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.addUserFavourite(userId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUser(userId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} postId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserFavourite(userId: number, postId: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserFavourite(userId, postId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCurrentUser(options?: any): AxiosPromise<User> {
            return localVarFp.getCurrentUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserById(userId: number, options?: any): AxiosPromise<User> {
            return localVarFp.getUserById(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} searchTerm 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserBySearchTerm(searchTerm: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUserBySearchTerm(searchTerm, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserFavourite(userId: number, options?: any): AxiosPromise<Set<number>> {
            return localVarFp.getUserFavourite(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} limit 
         * @param {number} lastId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUsers(limit: number, lastId: number, options?: any): AxiosPromise<Array<User>> {
            return localVarFp.getUsers(limit, lastId, options).then((request) => request(axios, basePath));
        },
        /**
         * Register new user
         * @param {NewUser} [newUser] New user information
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUser(newUser?: NewUser, options?: any): AxiosPromise<User> {
            return localVarFp.registerUser(newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * Register new user with OAuth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUserWithOAuth(options?: any): AxiosPromise<User> {
            return localVarFp.registerUserWithOAuth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {NewUser} [newUser] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserById(userId: number, newUser?: NewUser, options?: any): AxiosPromise<User> {
            return localVarFp.updateUserById(userId, newUser, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {string} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserRoleById(userId: number, body?: string, options?: any): AxiosPromise<User> {
            return localVarFp.updateUserRoleById(userId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {number} userId 
     * @param {number} [body] Add favourite post id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public addUserFavourite(userId: number, body?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).addUserFavourite(userId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUser(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {number} postId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserFavourite(userId: number, postId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserFavourite(userId, postId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getCurrentUser(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getCurrentUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserById(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserById(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} searchTerm 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserBySearchTerm(searchTerm: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserBySearchTerm(searchTerm, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserFavourite(userId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserFavourite(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} limit 
     * @param {number} lastId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUsers(limit: number, lastId: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUsers(limit, lastId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register new user
     * @param {NewUser} [newUser] New user information
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerUser(newUser?: NewUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerUser(newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Register new user with OAuth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public registerUserWithOAuth(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).registerUserWithOAuth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {NewUser} [newUser] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserById(userId: number, newUser?: NewUser, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserById(userId, newUser, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} userId 
     * @param {string} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateUserRoleById(userId: number, body?: string, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).updateUserRoleById(userId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


